import InstantPayments from '@/components/wallet/InstantPayments'
import CurrencySupport from '@/components/wallet/CurrencySupport'
import Secure from '@/components/wallet/Secure'
import BuiltInExchange from '@/components/wallet/BuiltInExchange'

export default {
  name: 'YourBenefits',
  components: {
    InstantPayments,
    CurrencySupport,
    Secure,
		BuiltInExchange
  }
}
