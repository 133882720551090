import { render, staticRenderFns } from "./WalletPage.vue?vue&type=template&id=80011722&scoped=true&"
import script from "./wallet-page?vue&type=script&lang=js&"
export * from "./wallet-page?vue&type=script&lang=js&"
import style0 from "./WalletPage.vue?vue&type=style&index=0&id=80011722&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80011722",
  null
  
)

export default component.exports