<template>
  <div class="content">
    <v-row no-gutters class="flex-column mb-10 mb-md-15">
      <v-col cols="12" align-self="center">
        <p
					class="vue-editor text-md-h2 text-body-1 text-center font-weight-black"
					v-html="trans('wallet_page_get_wallet_title')"
				/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-center">
        <GetWalletBtn />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GetWalletBtn from '@/components/wallet/wallet-buttons/GetWalletBtn.vue'

export default {
  name: 'GetWallet',
  components: {
    GetWalletBtn
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: 486px;
  margin-bottom: 150px;
}
.sub-title {
  max-width: 360px;
}
@media #{map-get($display-breakpoints, 'md-only')} {
	.content {
		margin-top: 33.75vw;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content {
    margin-top: 74px;
    margin-bottom: 40px;
  }
}
</style>
