<template>
<div class="section-wrapper">
  <v-img
		v-if="$vuetify.breakpoint.mdAndUp"
		:width="windowWidth >= 1440 ? '929' : '64%'"
		src="@/assets/images/invoice-with-shadow.png"
		class="main-image"
	/>
  <div class="content">
    <v-row class="justify-space-between ma-0">
      <v-col cols="12" md="7" class="pa-0 order-2 order-md-1">
        <v-img
					v-if="$vuetify.breakpoint.smAndDown"
					width="100%"
					src="@/assets/images/invoice-with-shadow.png"
					class="main-image"
				/>
      </v-col>
      <v-col cols="12" md="4" class="text-md-end pa-0 order-1 order-md-2">
        <h3
					class="vue-editor text-body-1 text-md-h4 font-weight-bold mb-5 mb-md-9"
					v-html="trans('wallet_page_support_title')"
				/>
        <p
					class="vue-editor text-body-2 grayText--text text-md-subtitle-1"
					v-html="trans('wallet_page_support_content')"
				/>
        <MultipleCurrenciesBtn class="btn mt-8 mt-md-15" />
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
import MultipleCurrenciesBtn from '@/components/wallet/wallet-buttons/MultipleCurrenciesBtn.vue'

export default {
  name: 'CurrencySupport',
  components: {
    MultipleCurrenciesBtn
  }
}
</script>

<style lang="scss" scoped>
.section-wrapper {
  position: relative;
  &::before {
    position: absolute;
		content: "";
		width: 750px;
		height: 750px;
		top: -183px;
		left: -240px;
		border-radius: 50%;
		background: var(--v-mainColor-base);
		opacity: 0.1;
		z-index: 0;
  }
}
.content {
  margin-top: 473px;
  position: relative;
}
.btn {
  margin-right: 0 !important;
}
.main-image {
	position: absolute !important;
	top: -87px;
	left: 0px;
	z-index: 10;
}
@media #{map-get($display-breakpoints, 'xl-only')} {
	.main-image {
		left: 43px;
	}
}
@media #{map-get($display-breakpoints, 'md-only')} {
	.content {
		margin-top: 32.85vw;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .section-wrapper {
    &::before {
      width: 313px;
			height: 313px;
			top: initial;
			bottom: -20px;
			right: -144px;
			left: auto;
    }
  }
  .main-image {
		position: relative !important;
		top: 0;
		margin-top: 40px;
	}
  .content {
    margin-top: 73px;
  }
	.main-image {
		position: relative !important;
		top: 0;
		margin-top: 46px;
		transform: scale(1.19) translateX(-1px);
	}
}
</style>
