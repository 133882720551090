import { render, staticRenderFns } from "./SecureBtn.vue?vue&type=template&id=6815272e&scoped=true&"
import script from "./SecureBtn.vue?vue&type=script&lang=js&"
export * from "./SecureBtn.vue?vue&type=script&lang=js&"
import style0 from "./SecureBtn.vue?vue&type=style&index=0&id=6815272e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6815272e",
  null
  
)

export default component.exports