import SubheaderBtn from '@/components/wallet/wallet-buttons/SubheaderBtn.vue'
import CustomList from '@/components/custom-list/CustomList.vue'

export default {
  name: 'Subheader',
  components: {
    SubheaderBtn,
		CustomList
  },
	computed: {
		list () {
			return [
				this.trans('wallet_page_preview_list_item_1'),
				this.trans('wallet_page_preview_list_item_2'),
				this.trans('wallet_page_preview_list_item_3')
			]
		}
	}
}
