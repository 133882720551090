<template>
<div class="section-wrapper">
  <v-img
		v-if="$vuetify.breakpoint.mdAndUp"
		:width="windowWidth >= 1440 ? '929' : '64%'"
		src="@/assets/images/checkout-with-shadow.png"
		class="main-image"
	/>
  <div class="content">
    <v-row class="justify-space-between ma-0">
      <v-col cols="12" md="4" class="pa-0 pr-6">
        <h3
					class="vue-editor text-body-1 text-md-h4 font-weight-bold mb-5 mb-md-9"
					v-html="trans('wallet_page_secure_title')"
				/>
        <p
					class="vue-editor text-content text-body-2 grayText--text text-md-subtitle-1"
					v-html="trans('wallet_page_secure_content')"
				/>
        <SecureBtn class="mt-8 mt-md-15" />
      </v-col>
      <v-col cols="12" md="8" class="pa-0">
        <div class="d-flex justify-end mr-md-10">
          <v-img
						v-if="$vuetify.breakpoint.smAndDown"
						width="100%"
						src="@/assets/images/checkout-with-shadow.png"
						class="main-image"
					/>
        </div>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
import SecureBtn from '@/components/wallet/wallet-buttons/SecureBtn.vue'

export default {
  name: 'ChooseBusiness',
  components: {
    SecureBtn
  }
}
</script>

<style lang="scss" scoped>
.section-wrapper {
  position: relative;
  &::before {
    position: absolute;
		content: "";
		width: 750px;
		height: 750px;
		top: -177px;
		right: -323px;
		border-radius: 50%;
		background: var(--v-mainColor-base);
		opacity: 0.1;
		z-index: 0;
  }
}
.content {
  margin-top: 494px;
  position: relative;
}
.title {
  margin-bottom: 100px;
}
.text-content {
  width: 90%;
}
.main-image {
	position: absolute !important;
	top: -86px;
	right: 8px;
	z-index: 10;
}
@media #{map-get($display-breakpoints, 'md-only')} {
	.content {
		margin-top: 34.31vw;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .section-wrapper {
    &::before {
      width: 313px;
			height: 313px;
			top: initial;
			bottom: -30px;
			right: -144px;
    }
  }
  .main-image {
		position: relative !important;
		top: 0;
		margin-top: 40px;
	}
  .content {
    margin-top: 53px;
  }
  .title {
    margin-bottom: 40px;
  }
	.main-image {
		position: relative !important;
		top: 12px;
		margin-top: 46px;
		right: initial;
		transform: scale(1.19) translateX(-1px);
	}
}
@media #{map-get($display-breakpoints, 'xl-only')} {
	.section-wrapper {
		&:before {
			right: -282px;
		}
	}
	.main-image {
		right: 49px;
	}
}
</style>
