import Subheader from '@/components/wallet/subheader/Subheader.vue'
import YourBenefits from '@/components/wallet/your-benefits/YourBenefits'
import GetWallet from '@/components/wallet/GetWallet'

export default {
  name: 'WalletPage',
  components: {
    Subheader,
    YourBenefits,
    GetWallet
  },
	metaInfo () {
		return {
			title: 'Best Multi-currency crypto wallet | 1TN',
			meta: [{
				name: 'description',
				content: ' Securely keep your crypto with 1TN wallet service.Manage and exchange cryptocurrencies',
			},
			{
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'Best Multi-currency crypto wallet | 1TN',
			},
			{
				name: 'og:description',
				content: ' Securely keep your crypto with 1TN wallet service.Manage and exchange cryptocurrencies',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'Best Multi-currency crypto wallet | 1TN',
			},
			{
				name: 'twitter:description',
				content: ' Securely keep your crypto with 1TN wallet service.Manage and exchange cryptocurrencies',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
		],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	}
}
